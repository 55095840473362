// Here you can add other styles

.map {
  border: 1px solid $gray-500; 
  position: absolute;
  width: calc(100% - 240px);
  left: 220px;
  top: 120px;
  bottom: 20px;
}

.result-slider {
  width: 30vh
}

.map-overlay {
  background-color: rgba(255, 255, 255, 0.849);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.10);
  position: absolute;
  width: 350px;
  top: 130px;
  left: 230px;
  padding: 0px;
  z-index: 100;
}

.map-overlay .inner {
  padding: 10px;
}

.map-overlay slider {
  width: 100%;
}

.map-overlay Label {
  margin: 0px;
}
.map-overlay hr {
 margin: 5px;
}

.map-overlay-results {
  background-color: rgba(255, 255, 255, 0.849);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.10);
  position: absolute;
  width: 200px;
  top: 130px;
  left: 585px;
  padding: 0px;
  z-index: 100;
}

.map-overlay-results .inner {
  padding: 10px;
}

.map-overlay-image {
  background-color: rgba(255, 255, 255, 0.849);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.10);
  position: absolute;
  width: 512px;
  top: 130px;
  right: 30px;
  padding: 0px;
  z-index: 100;
  overflow: hidden;
}

.map-overlay-image img {
  width: 1024px;
  height: 768px;
  margin-left: -512px;
}

.tabish {
  background-color: rgba(255, 255, 255, 0.2);
}

.login {
  top:50%;
  left: 50%;
  transform: translate3d(-50%,-50%, 0);
  position: absolute;
}

.login .box {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.10);
  border: 1px solid $gray-500; 
  height: 150px;
  width: 300px;
}

.dot {
  height: 12px;
  width: 12px;
  border-style: solid;
  border-radius: 50%;
  border-color: #000;
  border-width: 1px;
  display: inline-block;
}